.plan {
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.4);
  .container {
    display: flex;
    flex-direction: column;
    .planHeader {
      border-radius: 16px 16px 0 0;
      padding: 2px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      font-size: 13px;
    }
  }
  .planContent {
    padding: 12px 20px 12px 12px;
    border-radius: 16px;
    border: 2px solid #001d671a;
    background-color: #f6f7f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #00000099;
      .priceText {
        display: flex;
        font-weight: 500;
        flex-direction: column;
        h5 {
          font-size: 14px !important;
          font-weight: 700;
          color: #00000099;
        }
        p {
          font-size: 12px;
          .priceOld {
            text-decoration: line-through;
            text-decoration-color: #f91e1e;
          }
        }
      }
    }

    .pricePerDay {
      color: #00000099;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      p {
        font-size: 26px;
        line-height: 33px;
      }
      .perDay {
        font-size: 13px;
        line-height: 16px;
      }
      .perDayOld {
        font-size: 12px;
        text-decoration: line-through;
        line-height: 16px;
        color: #00000099;
      }
    }
  }
}

.checked {
  background-color: var(--main-pink);
  .planContent {
    border-color: var(--main-pink);
    background-color: #fff;
    color: #000;
  }
  .container {
    .planHeader {
      background-color: var(--main-pink);
    }

    .priceText {
      color: #000;
      h5 {
        color: #000;
      }
      p {
        span {
          color: #000;
        }
      }
    }
    .pricePerDay {
      color: #000;
      .perDayOld {
        color: #000;
      }
    }
  }
}
