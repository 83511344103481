.cardWrapper {
  max-width: 400px;
  margin: auto;
  width: 100%;

  .guarantee {
    text-align: center;
    margin-bottom: 20px;
    p {
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  label {
    font-size: 14px;
    color: #424770;
    margin-bottom: 5px;
  }

  .cardInput {
    padding: 12px;
    border: 1px solid #dfe1e5;
    border-radius: 8px;
    background-color: #fff;
  }

  .splitFields {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .expiryDate,
  .cvv {
    flex: 1;
  }
}

.inputField {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #dfe1e5;
}

.inputField::placeholder {
  color: #aab7c4;
}

.control {
  color: #828282;
  font-size: 36px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p {
      font-size: 18px;
      letter-spacing: 1.41px;
    }

    .spinner {
      width: 26px;
      height: 26px;
      border: 2px solid #ffffff;
      border-top: 2px solid transparent;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.resultMessage {
  line-height: 22px;
  font-size: 16px;
}

.hidden {
  display: none;
}

.error {
  color: red;
  font-size: 14px;
}

.blackButton {
  background-color: #000 !important;
  color: #fff !important;
  height: 52px;
  
  &:disabled {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}
